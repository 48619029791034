import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue'; 
import DetailPage from '../views/DetailPage.vue'; 
import ClaudeComputerUse from '../views/ClaudeComputerUse.vue'; 
import Gpt from '../views/Gpt.vue'; 
import Zhipu from '../views/Zhipu.vue'; 
import Lingyiwanwu from '../views/Lingyiwanwu.vue'; 
import o1 from '../views/o1.vue'; 
import xai from '../views/xai.vue'; 
import mistral from '../views/mistral.vue'; 
import llama from '../views/llama.vue'; 
import qwen from '../views/qwen.vue'; 
import doubao from '../views/doubao.vue'; 
import Claude35Haiku from '../views/Claude35Haiku.vue'; 
const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage 
  },
  {
    path: '/detail/:id',
    name: 'Detail',
    component: DetailPage, // 详情页
    props: true // 将路由参数传递给详情页
  },
  {
    path: '/ClaudeComputerUse',
    name: 'ClaudeComputerUse',
    component: ClaudeComputerUse 
  },
  {
    path: '/Gpt',
    name: 'Gpt',
    component: Gpt 
  },
  {
    path: '/Zhipu',
    name: 'Zhipu',
    component: Zhipu 
  },
  {
    path: '/Lingyiwanwu',
    name: 'Lingyiwanwu',
    component: Lingyiwanwu
  },
  {
    path: '/o1',
    name: 'o1',
    component: o1 
  },
  {
    path: '/xai',
    name: 'xai',
    component: xai 
  },
  {
    path: '/mistral',
    name: 'mistral',
    component: mistral 
  },
  {
    path: '/llama',
    name: 'llama',
    component: llama 
  },
  {
    path: '/qwen',
    name: 'qwen',
    component: qwen 
  },
  {
    path: '/doubao',
    name: 'doubao',
    component: doubao 
  },
  {
    path: '/claude-35-haiku',
    name: 'claude-35-haiku',
    component: Claude35Haiku 
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
