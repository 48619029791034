<template>
  <div class="flex h-screen">
    <!-- 左侧文件列表 -->
    <div class="min-w-[200px] max-w-[200px] flex flex-col border-r border-gray-200">
      <!-- 文件列表头部 -->
      <div
        class="flex-shrink-0 flex items-center justify-between bg-white border-b border-gray-200 px-4 h-[50px]">
        <div class="font-bold text-md text-gray-700">文件列表</div>
        <div class="flex items-center space-x-2">
          <button @click="createNewFile"
            class="text-gray-700 hover:bg-gray-300 transition-colors text-sm flex items-center justify-center">
            <Plus :size="16" color="gray" />
          </button>
          <button @click="deleteFile"
            class="text-gray-700 hover:bg-gray-300 transition-colors text-sm flex items-center justify-center">
            <Trash2 :size="16" color="gray" />
          </button>
        </div>
      </div>
      <!-- 文件列表 -->
      <div class="flex-1 overflow-auto">
        <ul>
          <li v-for="file in files" :key="file.path" @click="selectFile(file)"
            :class="{'bg-gray-100': file.path === currentFile.path}"
            class="px-4 py-2 cursor-pointer hover:bg-gray-200">
            {{ file.name }}
          </li>
        </ul>
      </div>
    </div>

    <!-- 中间编辑器 -->
    <div class="min-w-[400px] flex-1 flex flex-col overflow-hidden">
      <div
        class="flex justify-between items-center bg-white border-b border-gray-200 px-4 py-2.5 h-[50px]">
        <div class="flex items-center space-x-2">
          <button @click="goBack"
            class="text-gray-700 px-3 py-2 rounded hover:bg-gray-300 transition-colors text-sm flex items-center justify-center">
            <ArrowLeft :size="16" color="gray" />
          </button>
          <div class="font-bold text-md text-gray-700">{{ currentFile.name }}</div>
        </div>
        <div class="flex space-x-3">
          <div class="relative">
            <div v-if="versionHistory.length > 0"
              class="bg-gray-200 px-4 py-2 cursor-pointer rounded text-sm flex justify-between items-center"
              @click="toggleDropdown">
              <Clock :size="16" color="gray" />
            </div>

            <ul v-if="showDropdown"
              class="absolute bg-white border border-gray-200 mt-1 rounded w-[168px] z-10 max-h-60 overflow-y-auto ">
              <li v-for="(version, index) in versionHistory" :key="index" @click="selectVersion(index)"
                class="px-4 py-2 cursor-pointer hover:bg-gray-100 text-sm">
                <div>{{ version.name }}</div>
                <div class="text-xs text-gray-500">{{ version.timestamp }}</div>
              </li>
            </ul>
          </div>

          <button @click="copyContent"
            class="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 transition-colors text-sm flex items-center space-x-1">
            <Copy :size="16" color="gray" />
          </button>

          <button @click="downloadContent"
            class="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 transition-colors text-sm flex items-center space-x-1">
            <Download :size="16" color="gray" />
          </button>
        </div>
      </div>

      <div class="h-full bg-white p-4 pr-1">
        <textarea v-model="currentFile.content" placeholder="写点什么..."
          class="w-full h-full resize-none outline-none bg-transparent custom-scrollbar" @input="updateBlocks"
          @keydown.enter="handleEnter" />
      </div>

    </div>

    <!-- 右侧聊天组件 -->
    <div class="min-w-[400px] max-w-[400px] flex flex-col border-l border-gray-200">
      <div
        class="flex-shrink-0 flex items-center justify-center text-gray-500 h-[50px] border-b border-gray-200 pl-2">
        <Sparkles :size="24" color="gray" />
        <span class="text-md ml-2">智能助理</span>
      </div>
      <!-- 聊天组件 -->
      <chat :getAppData="getAppData" :runTool="runTool" ref="Ai" />
    </div>

  </div>
</template>

<script setup>
import { ref, onMounted, nextTick } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { ArrowLeft, Clock, Copy, Download, Sparkles, Plus, Trash2 } from 'lucide-vue-next'
import chat from '../components/AI/chat/anthropic/stream-computer.vue';
// import { post } from '../utils';

const router = useRouter();
// const route = useRoute();

const generateRandomId = () => Math.random().toString(36).substring(2, 8);
// const draftId = ref('');
// const articleTitle = ref('新文档');
const Ai = ref(null);

// 模拟的文件系统，初始包含一个文件
const files = ref([
  { path: '/file1.txt', name: 'file1.txt', content: '', blocks: [] }
]);

const currentFile = ref(files.value[0]); // 当前选中的文件

const versionHistory = ref([]);  // 版本历史
const selectedVersion = ref(0); // 默认选中的版本索引
const showDropdown = ref(false); // 控制下拉框显示

// 获取应用数据，供聊天组件使用
const getAppData = () => {
  return '当前文件路径:' + currentFile.value.path + '\n\n' + currentFile.value.content;
};

// 切换版本下拉框的显示
const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;
};

// 选择版本
const selectVersion = (index) => {
  selectedVersion.value = index;
  currentFile.value.content = versionHistory.value[index].content; // 恢复该版本的内容
  showDropdown.value = false; // 关闭下拉菜单
};

// 更新块内容，根据换行符将内容分段
const updateBlocks = () => {
  const paragraphs = currentFile.value.content.split('\n');
  currentFile.value.blocks = paragraphs.map(paragraph => ({
    id: generateRandomId(),
    type: 'text',
    content: paragraph
  }));
};

// 处理 Enter 键
const handleEnter = (event) => {
  if (!event.shiftKey) {
    event.preventDefault(); // 禁用 Enter 换行行为
    const caretPosition = event.target.selectionStart;
    currentFile.value.content = currentFile.value.content.slice(0, caretPosition) + '\n' + currentFile.value.content.slice(caretPosition);
    nextTick(() => {
      event.target.selectionStart = caretPosition + 1;
      event.target.selectionEnd = caretPosition + 1;
      updateBlocks();
    });
  }
};

// 工具调用函数
// const runTool = async (toolCalls) => {

//   console.log('工具列表',JSON.stringify(toolCalls) )

//   const toolResults = [];
//   let funContent = '';
//   let versionContent = '';
//   for (const call of toolCalls) {
//     const parsedArgs = call.input;
//     switch (call.name) {
//       case 'text_editor_20241022':
//         {
//           const { command, path, file_text, old_str, new_str, insert_line, view_range } = parsedArgs;
//           switch (command) {
//             case 'view':
//               {
//                 const file = files.value.find(f => f.path === path);
//                 if (file) {
//                   if (view_range && view_range.length === 2) {
//                     const lines = file.content.split('\n');
//                     const start = view_range[0] - 1;
//                     const end = view_range[1] === -1 ? lines.length : view_range[1];
//                     funContent = lines.slice(start, end).join('\n');
//                   } else {
//                     funContent = file.content;
//                   }
//                 } else {
//                   funContent = `文件 ${path} 不存在。`;
//                 }
//               }
//               break;
//             case 'create':
//               {
//                 const existingFile = files.value.find(f => f.path === path);
//                 if (existingFile) {
//                   funContent = `文件 ${path} 已存在，无法创建。`;
//                 } else {
//                   const fileName = path.split('/').pop();
//                   const newFile = {
//                     path: path,
//                     name: fileName,
//                     content: file_text || '',
//                     blocks: []
//                   };
//                   files.value.push(newFile);
//                   funContent = `文件 ${path} 创建成功。`;
//                 }
//               }
//               break;
//             case 'str_replace':
//               {
//                 const file = files.value.find(f => f.path === path);
//                 if (file) {
//                   const occurrences = (file.content.match(new RegExp(old_str, 'g')) || []).length;
//                   if (occurrences === 1) {
//                     file.content = file.content.replace(old_str, new_str || '');
//                     funContent = `文件 ${path} 中的内容已替换。`;
//                   } else if (occurrences === 0) {
//                     funContent = `未找到匹配的字符串。`;
//                   } else {
//                     funContent = `找到多个匹配的字符串，替换失败。`;
//                   }
//                 } else {
//                   funContent = `文件 ${path} 不存在。`;
//                 }
//               }
//               break;
//             case 'insert':
//               {
//                 const file = files.value.find(f => f.path === path);
//                 if (file) {
//                   const lines = file.content.split('\n');
//                   const index = insert_line;
//                   lines.splice(index, 0, new_str);
//                   file.content = lines.join('\n');
//                   funContent = `已在文件 ${path} 的第 ${insert_line} 行后插入内容。`;
//                 } else {
//                   funContent = `文件 ${path} 不存在。`;
//                 }
//               }
//               break;
//             case 'undo_edit':
//               {
//                 // 简单实现：恢复到上一个版本
//                 if (versionHistory.value.length > 1) {
//                   versionHistory.value.shift(); // 移除最新的版本
//                   currentFile.value.content = versionHistory.value[0].content;
//                   funContent = `已撤销对文件 ${path} 的最后一次编辑。`;
//                 } else {
//                   funContent = `没有可以撤销的编辑。`;
//                 }
//               }
//               break;
//             default:
//               funContent = `未识别的命令: ${command}`;
//           }
//         }
//         break;
//       default:
//         funContent = `未识别的工具调用: ${call.name}`;
//     }

//     toolResults.push({
//       type: 'tool_result',
//       tool_use_id: call.id,
//       content: funContent
//     });

//     // 保存版本历史
//     addVersion(funContent);
//     saveDraft();
//   }
//   return toolResults;
// };


// 工具调用函数
const runTool = async (toolCalls) => {
  const toolResults = [];

  for (const call of toolCalls) {
    const { id, name, input } = call;
    let funContent = '';

    if (name === 'str_replace_editor') {
      // 处理 text_editor_20241022 的功能
      const { command, path, file_text, old_str, new_str, insert_line, view_range } = input;

      switch (command) {
        case 'view':
          {
            const file = files.value.find(f => f.path === path);
            if (file) {
              if (view_range && view_range.length === 2) {
                const lines = file.content.split('\n');
                const start = view_range[0] - 1;
                const end = view_range[1] === -1 ? lines.length : view_range[1];
                funContent = lines.slice(start, end).join('\n');
              } else {
                funContent = file.content;
              }
            } else {
              funContent = `文件 ${path} 不存在。`;
            }
          }
          break;
        case 'create':
          {
            const existingFile = files.value.find(f => f.path === path);
            if (existingFile) {
              funContent = `文件 ${path} 已存在，无法创建。`;
            } else {
              const fileName = path.split('/').pop();
              const newFile = {
                path: path,
                name: fileName,
                content: file_text || '',
                blocks: []
              };
              files.value.push(newFile);
              funContent = `文件 ${path} 创建成功。`;
            }
          }
          break;
        case 'str_replace':
          {
            const file = files.value.find(f => f.path === path);
            if (file) {
              const occurrences = (file.content.match(new RegExp(old_str, 'g')) || []).length;
              if (occurrences === 1) {
                file.content = file.content.replace(old_str, new_str || '');
                funContent = `文件 ${path} 中的内容已替换。`;
              } else if (occurrences === 0) {
                funContent = `未找到匹配的字符串。`;
              } else {
                funContent = `找到多个匹配的字符串，替换失败。`;
              }
            } else {
              funContent = `文件 ${path} 不存在。`;
            }
          }
          break;
        case 'insert':
          {
            const file = files.value.find(f => f.path === path);
            if (file) {
              const lines = file.content.split('\n');
              const index = insert_line;
              lines.splice(index, 0, new_str);
              file.content = lines.join('\n');
              funContent = `已在文件 ${path} 的第 ${insert_line} 行后插入内容。`;
            } else {
              funContent = `文件 ${path} 不存在。`;
            }
          }
          break;
        case 'undo_edit':
          {
            // 简单实现：恢复到上一个版本
            if (versionHistory.value.length > 1) {
              versionHistory.value.shift(); // 移除最新的版本
              currentFile.value.content = versionHistory.value[0].content;
              funContent = `已撤销对文件 ${path} 的最后一次编辑。`;
            } else {
              funContent = `没有可以撤销的编辑。`;
            }
          }
          break;
        default:
          funContent = `未识别的命令: ${command}`;
      }
    } else {
      funContent = `未识别的工具调用: ${name}`;
    }

    toolResults.push({
      type: 'tool_result',
      tool_use_id: id,
      content: funContent
    });

    // // 保存版本历史或更新草稿
    // if (name === 'str_replace_editor') {
    //   addVersion(funContent);
    //   saveDraft();
    // }
  }

  return toolResults;
};

// 更新草稿内容
// const saveDraft = async () => {
//   let userId = localStorage.getItem('user_id');
//   const response = await fetch('/api/write/save', {
//     method: 'POST',
//     headers: { 'Content-Type': 'application/json' },
//     body: JSON.stringify({
//       draft_id: draftId.value,
//       user_id: userId,
//       content: currentFile.value.content || '空内容'
//     })
//   });

//   if (response.ok) {
//     console.log('Content updated successfully');
//   } else {
//     const data = await response.json();
//     console.error('Failed to update content:', data.error);
//   }
// };

// 复制和下载功能
const copyContent = () => {
  navigator.clipboard.writeText(currentFile.value.content)
    .then(() => console.log('内容已复制'))
    .catch(() => console.log('复制失败'));
};

const downloadContent = () => {
  const blob = new Blob([currentFile.value.content], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${currentFile.value.name}`;
  a.click();
  URL.revokeObjectURL(url);
};

// 添加版本历史记录
const addVersion = (operationName) => {
  const newVersion = {
    name: `${operationName}`,
    content: currentFile.value.content,
    timestamp: new Date().toLocaleString(),
  };
  versionHistory.value.unshift(newVersion);
  selectedVersion.value = 0; // 自动选中最新版本
};

// 创建新文件
const createNewFile = () => {
  const fileName = prompt('请输入新文件名（包含扩展名）：');
  if (fileName) {
    const path = '/' + fileName;
    const existingFile = files.value.find(f => f.path === path);
    if (existingFile) {
      alert('文件已存在！');
    } else {
      const newFile = {
        path: path,
        name: fileName,
        content: '',
        blocks: []
      };
      files.value.push(newFile);
      selectFile(newFile);
    }
  }
};

// 删除当前文件
const deleteFile = () => {
  if (confirm(`确定要删除文件 ${currentFile.value.name} 吗？`)) {
    const index = files.value.findIndex(f => f.path === currentFile.value.path);
    if (index !== -1) {
      files.value.splice(index, 1);
      // 如果没有文件了，创建一个新文件
      if (files.value.length === 0) {
        const newFile = {
          path: '/untitled.txt',
          name: 'untitled.txt',
          content: '',
          blocks: []
        };
        files.value.push(newFile);
      }
      selectFile(files.value[0]);
    }
  }
};

// 选择文件
const selectFile = (file) => {
  currentFile.value = file;
  versionHistory.value = []; // 切换文件时清空版本历史
  addVersion('打开文件');
};

// 初始化获取草稿详情
// const fetchDraftDetails = async () => {
//   let userId = localStorage.getItem('user_id');
//   try {
//     const response = await fetch('/api/write/detail', {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({
//         draft_id: draftId.value,
//         user_id: userId
//       })
//     });

//     const data = await response.json();
//     if (data.draft.status === 0) {
//       console.log('开始写作');
//       const startReq = await fetch('/api/write/start', {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({
//           draft_id: draftId.value,
//           user_id: userId
//         })
//       });
//       if (startReq.ok) {
//         Ai.value.sendStartMessage(`写一篇标题为 ${data.draft.title} 的文章`);
//       }
//     } else {
//       articleTitle.value = data.draft.title;
//       currentFile.value.content = data.draft.content;
//       updateBlocks();
//     }
//   } catch (error) {
//     console.error('Error fetching draft details:', error);
//   }
// };

onMounted(async () => {
  // draftId.value = route.params.id;
  // await fetchDraftDetails();
});

const goBack = () => {
  router.push('/');
};
</script>

<style scoped>
/* 自定义滚动条样式 */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}
</style>
