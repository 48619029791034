<template>
  <div class="flex h-screen">
    <div class="min-w-[400px] flex-1 flex flex-col overflow-hidden">
      <div class="flex justify-between items-center bg-white border-b border-gray-200 px-4 py-2.5 h-[50px]">
        <div class="flex items-center space-x-2">
          <button @click="goBack"
            class=" text-gray-700 px-3 py-2 rounded hover:bg-gray-300 transition-colors text-sm flex items-center justify-center">
            <ArrowLeft :size="16" color="gray" />
          </button>
          <div class="font-bold text-md text-gray-700">{{ articleTitle }}</div>
        </div>
        <div class="flex space-x-3">
          <div class="relative">

            <div v-if="versionHistory.length > 0"
              class="bg-gray-200 px-4 py-2 cursor-pointer rounded text-sm flex justify-between items-center"
              @click="toggleDropdown">
              <Clock :size="16" color="gray" />
            </div>

            <ul v-if="showDropdown"
              class="absolute bg-white border border-gray-200 mt-1 rounded w-[168px] z-10 max-h-60 overflow-y-auto ">
              <li v-for="(version, index) in versionHistory" :key="index" @click="selectVersion(index)"
                class="px-4 py-2 cursor-pointer hover:bg-gray-100 text-sm">
                <div>{{ version.name }}</div>
                <div class="text-xs text-gray-500">{{ version.timestamp }}</div>
              </li>
            </ul>
          </div>

          <button @click="copyContent"
            class="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 transition-colors text-sm flex items-center space-x-1">
            <Copy :size="16" color="gray" />
            <!-- <span>复制</span> -->
          </button>

          <button @click="downloadContent"
            class="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 transition-colors text-sm flex items-center space-x-1">
            <Download :size="16" color="gray" />
            <!-- <span>下载</span> -->
          </button>
        </div>
      </div>

      <div class="h-full bg-white p-4 pr-1">
        <textarea v-model="editorContent" placeholder="写点什么..."
          class="w-full h-full resize-none outline-none bg-transparent custom-scrollbar" @input="updateBlocks"
          @keydown.enter="handleEnter" />
      </div>

    </div>

    <div class="min-w-[400px] max-w-[400px] flex flex-col border-l border-gray-200">
      <div class="flex-shrink-0 flex items-center justify-center text-gray-500 h-[50px] border-b border-gray-200 pl-2">
        <Sparkles :size="24" color="gray" />
        <span class="text-md ml-2">智能助理</span>
      </div>
      <!-- 聊天组件 -->
      <chat :getAppData="getAppData" :runTool="runTool" ref="Ai" />
    </div>


  </div>
</template>


<script setup>
import { ref, onMounted, nextTick } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { ArrowLeft, Clock, Copy, Download, Sparkles } from 'lucide-vue-next'
import chat from '../components/AI/chat/anthropic/stream-35-haiku.vue';
import { post } from '../utils';

const router = useRouter();
const route = useRoute();

const fetchDraftDetails = async () => {
  let userId = localStorage.getItem('user_id');
  try {
    const response = await fetch('/api/write/detail', { // 假设这个接口路径是 /api/write/detail
      method: 'POST', // 根据接口文档决定请求方法，假设这里是 POST
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        draft_id: draftId.value,
        user_id: userId
      })
    });

    const data = await response.json();
    // console.log('详情', data)
    if (data.draft.status === 0) {  // response.ok 自动检查状态码是否在 200-299 之间
      console.log('开始写作')

      const startReq = await fetch('/api/write/start', { // 假设这个接口路径是 /api/write/detail
        method: 'POST', // 根据接口文档决定请求方法，假设这里是 POST
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          draft_id: draftId.value,
          user_id: userId
        })
      });
      if (startReq.ok) {
        Ai.value.sendStartMessage(`写一篇标题为 ${data.draft.title} 的文章`);
      }

    } else {
      articleTitle.value = data.draft.title
      editorContent.value = data.draft.content
      updateBlocks(); // 更新块
    }
  } catch (error) {
    console.error('Error fetching draft details:', error);
  }
};

const generateRandomId = () => Math.random().toString(36).substring(2, 8);
const draftId = ref('');
const articleTitle = ref('新文档');
const editorContent = ref('');
const Ai = ref(null);

const blocks = ref([
  { id: generateRandomId(), type: 'text', content: '' }
]);

const getAppData = () => {
  return '标题:' + articleTitle.value + '\n\n' + JSON.stringify(blocks.value)
};


const versionHistory = ref([]);  // 添加 versionHistory 的声明

const selectedVersion = ref(0); // 默认选中的版本索引
const showDropdown = ref(false); // 控制下拉框显示


// 下拉框切换显示
const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;
};

// 选择版本
const selectVersion = (index) => {
  selectedVersion.value = index;
  editorContent.value = versionHistory.value[index].content; // 恢复该版本的内容
  showDropdown.value = false; // 关闭下拉菜单
};

// 更新块内容，根据换行符将内容分段
const updateBlocks = () => {
  const paragraphs = editorContent.value.split('\n');
  blocks.value = paragraphs.map(paragraph => ({
    id: generateRandomId(),
    type: 'text',
    content: paragraph
  }));
};

// 处理 Enter 键
const handleEnter = (event) => {
  if (!event.shiftKey) {
    event.preventDefault(); // 禁用 Enter 换行行为
    const caretPosition = event.target.selectionStart;
    editorContent.value = editorContent.value.slice(0, caretPosition) + '\n' + editorContent.value.slice(caretPosition);
    nextTick(() => {
      event.target.selectionStart = caretPosition + 1;
      event.target.selectionEnd = caretPosition + 1;
      updateBlocks();
    });
  }
};

// 工具调用函数
const runTool = async (toolCalls) => {
  const toolResults = [];
  let funContent = '';
  let versionContent = ''
  for (const call of toolCalls) {

    const parsedArgs = call.input;

    switch (call.name) {
      case 'start':
        {
          const { title } = parsedArgs;
          articleTitle.value = title
          funContent = `已编辑标题`;
        }
        break;


      case 'write':
        {
          const { content } = parsedArgs;
          editorContent.value += '\n' + content; // 将新内容追加到编辑器内容
          updateBlocks(); // 更新块
          funContent = `已写入新内容`;
        }
        break;

      case 'edit':
        {
          const { blockId, newContent } = parsedArgs;
          const block = blocks.value.find((b) => b.id === blockId);
          if (block) {
            block.content = newContent;
            editorContent.value = blocks.value.map(block => block.content).join('\n'); // 同步编辑器内容
            funContent = `编辑成功`;
          } else {
            funContent = `找不到 ID 为 ${blockId} 的块。`;
          }
        }
        break;

      case 'insert':
        {
          const { blockId, newContent } = parsedArgs;
          const index = blocks.value.findIndex(block => block.id === blockId);
          if (index !== -1) {
            const newBlock = {
              id: generateRandomId(),
              type: 'text',
              content: newContent
            };
            blocks.value.splice(index + 1, 0, newBlock); // 在指定块之后插入新块
            editorContent.value = blocks.value.map(block => block.content).join('\n'); // 同步编辑器内容
            funContent = `已插入了新内容`;
          } else {
            funContent = `找不到 ID 为 ${blockId} 的块。`;
          }
        }
        break;

      case 'delete':
        {
          const { blockId } = parsedArgs;
          const index = blocks.value.findIndex(block => block.id === blockId);
          if (index !== -1) {
            blocks.value.splice(index, 1); // 删除指定块
            editorContent.value = blocks.value.map(block => block.content).join('\n'); // 同步编辑器内容
            funContent = `删除了内容。`;
          } else {
            funContent = `找不到 ID 为 ${blockId} 的块。`;
          }
        }
        break;

      case 'clear':
        {
          blocks.value = [{
            id: generateRandomId(),
            type: 'text',
            content: ''
          }];
          editorContent.value = ''; // 清空编辑器内容
          funContent = '编辑器已清空。';
        }
        break;

      case 'searchWeb':
        {
          try {
            const response = await post('/api/search', parsedArgs); // 等待 post 完成
            funContent = JSON.stringify(response); // 获取并设置搜索结果
            versionContent = '搜索了互联网'
          } catch (error) {
            console.error(error);
            funContent = '搜索出错了。';
          }
        }
        break;

      case 'getUrl':
        {
          try {
            const response = await post('/api/url', parsedArgs); // 等待 post 完成
            funContent = JSON.stringify(response); // 获取并设置URL结果
             versionContent = '阅读了url'
          } catch (error) {
            console.error(error);
            funContent = '获取URL时出错了。';
          }
        }
        break;
      default:
        funContent = `未识别的工具调用: ${call.name}`;
    }

    toolResults.push({
      type: 'tool_result',
      tool_use_id: call.id,
      content: funContent
    });
  }
  addVersion(versionContent || funContent)
  saveDraft()
  return toolResults; // 返回工具调用的结果
};



//更新修改
const saveDraft = async () => {
  let userId = localStorage.getItem('user_id');
  const response = await fetch('/api/write/save', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      draft_id: draftId.value,   // 传递草稿的ID
      user_id: userId,     // 传递用户的ID
      content: editorContent.value || '空内容' // 传递新的内容
    })
  });

  // 判断响应的状态码
  if (response.ok) {  // response.ok 自动检查状态码是否在 200-299 之间
    console.log('Content updated successfully');
  } else {
    const data = await response.json();  // 解析错误信息
    console.error('Failed to update content:', data.error);
  }
};

// 复制和下载功能
const copyContent = () => {
  navigator.clipboard.writeText(articleTitle.value + '\n' + editorContent.value)
    .then(() => console.log('内容已复制'))
    .catch(() => console.log('复制失败'));
};

const downloadContent = () => {
  const blob = new Blob([articleTitle.value + '\n' + editorContent.value], { type: 'application/msword' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${articleTitle.value}.doc`;
  a.click();
  URL.revokeObjectURL(url);
};

// 添加版本历史记录
const addVersion = (operationName) => {
  const newVersion = {
    name: `${operationName}`,
    content: editorContent.value,
    timestamp: new Date().toLocaleString(),
  };
  versionHistory.value.unshift(newVersion);
  selectedVersion.value = 0; // 自动选中最新版本
};


onMounted(async () => {
  draftId.value = route.params.id;
  await fetchDraftDetails();
});

const goBack = () => {
  router.push('/');
};

</script>