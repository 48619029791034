<template>
    <div class="flex h-screen">
      <!-- 左侧：版本历史记录 -->
      <div class="w-[200px] border-r border-gray-200 flex flex-col h-full ">
        <!-- 历史版本列表 -->
        <div class="flex justify-center items-center bg-white border-b border-gray-200 h-[46px]">
          版本记录
        </div>
  
        <ul class="divide-y divide-gray-200 flex-grow overflow-y-auto">
          <li v-for="(version, index) in versionHistory" :key="index" class="p-3 hover:bg-gray-100 cursor-pointer"
              @click="restoreVersion(index)">
            版本 {{ versionHistory.length - index }}
            <span class="text-xs text-gray-500 block">{{ version.timestamp }}</span>
          </li>
        </ul>
  
        <!-- 导出和导入按钮 -->
        <div class="p-3 flex justify-between items-center border-t border-gray-200">
          <button
            class="bg-gray-200 text-gray-700 px-4 py-1 rounded flex-grow mr-2 flex items-center justify-center hover:bg-gray-300 transition-colors"
            @click="exportVersionHistory">
            <span class="mr-2" style="font-size: 1.2em;">&#8595;</span>
            导出
          </button>
          <label
            class="bg-gray-200 text-gray-700 px-4 py-1 rounded flex-grow cursor-pointer text-center hover:bg-gray-300 transition-colors flex items-center justify-center">
            <span class="mr-2" style="font-size: 1.2em;">&#8593;</span>
            导入
            <input type="file" @change="importVersionHistory" class="hidden">
          </label>
        </div>
      </div>
  
      <!-- 中间：带标签页的编辑器 -->
      <div class="flex-1 flex flex-col overflow-hidden">
        <!-- 标签页 -->
        <div class="flex justify-between items-center bg-white border-b border-gray-200 h-[46px]">
          <!-- 标签页 -->
          <div class="flex">
            <div @click="switchToContent"
              :class="['relative px-4 py-2 font-medium cursor-pointer', activeTab === 'content' ? 'text-blue-600' : 'text-gray-500']">
              内容
              <span v-if="activeTab === 'content'"
                class="absolute bottom-0 left-0 w-full h-[2px] bg-blue-600"></span>
            </div>
            <div @click="activeTab = 'data'"
              :class="['relative px-4 py-2 font-medium cursor-pointer', activeTab === 'data' ? 'text-blue-600' : 'text-gray-500']">
              数据
              <span v-if="activeTab === 'data'"
                class="absolute bottom-0 left-0 w-full h-[2px] bg-blue-600"></span>
            </div>
          </div>
  
          <!-- 复制和下载按钮 -->
          <div class="flex space-x-2 mr-4">
            <button @click="copyContent"
              class="bg-gray-200 text-gray-700 px-4 py-1 rounded hover:bg-gray-300 transition-colors">
              复制
            </button>
            <button @click="downloadContent"
              class="bg-gray-200 text-gray-700 px-4 py-1 rounded hover:bg-gray-300 transition-colors">
              下载
            </button>
          </div>
        </div>
  
        <!-- 内容标签页 -->
        <div v-if="activeTab === 'content'" class="flex-1 overflow-y-auto">
          <div class="h-full bg-white p-2">
            <textarea v-model="editorContent" class="w-full h-full resize-none outline-none bg-transparent"
              @input="updateBlocks" @keydown.enter="handleEnter" />
          </div>
        </div>
  
        <!-- 数据标签页 -->
        <div v-else-if="activeTab === 'data'" class="flex-1 overflow-y-auto p-6">
          <pre class="text-sm overflow-x-auto">{{ JSON.stringify(blocks, null, 2) }}</pre>
        </div>
      </div>
  
      <!-- 右侧：模型选择和聊天组件 -->
      <div class="w-[400px] flex flex-col border-l border-gray-200">
        <!-- 模型选择下拉菜单 -->
        <div class="flex justify-center items-center p-2 border-b border-gray-200 h-[46px]">
          <select v-model="selectedModel" class="p-1 text-sm border border-gray-300 rounded">
            <option v-for="model in models" :key="model" :value="model">
              {{ model }}
            </option>
          </select>
        </div>
        <!-- 聊天组件 -->
        <chat 
          :model="selectedModel" 
          :appDataSync="appDataSync" 
          :getAppData="getAppData"
          :runPromptTool="runPromptTool" 
          ref="Ai" 
        />
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, nextTick } from 'vue';
  // import chat from '../../components/AI/chat/anthropic/stream.vue';
  import chat from '../components/AI/chat/openai/o1.vue';
//   import { post } from '../utils';
  
  // 模型列表
  const models = [
    'o1-mini',
    'o1-preview',
  ];

  const selectedModel = ref('o1-mini');
  const appDataSync = ref(true);
  const activeTab = ref('content');
  const editorContent = ref('');
  
  // 生成随机6位字符串作为 block id
  const generateRandomId = () => Math.random().toString(36).substring(2, 8);
  
  // 块列表，基于换行符将内容解析成多个段落
  const blocks = ref([
    { id: generateRandomId(), type: 'text', content: '' }
  ]);
  
  const versionHistory = ref([]);  // 添加 versionHistory 的声明
  
  // 切换到内容标签页
  const switchToContent = () => {
    activeTab.value = 'content';  // 将当前活动的标签页设为 'content'
    nextTick(() => {
      updateBlocks();  // 确保切换后更新块内容
    });
  };
  
  // 更新块内容，根据换行符将内容分段
  const updateBlocks = () => {
    const paragraphs = editorContent.value.split('\n');
    blocks.value = paragraphs.map(paragraph => ({
      id: generateRandomId(),
      type: 'text',
      content: paragraph
    }));
  };
  
  // 处理 Enter 键
  const handleEnter = (event) => {
    if (!event.shiftKey) {
      event.preventDefault(); // 禁用 Enter 换行行为
      const caretPosition = event.target.selectionStart;
      editorContent.value = editorContent.value.slice(0, caretPosition) + '\n' + editorContent.value.slice(caretPosition);
      nextTick(() => {
        event.target.selectionStart = caretPosition + 1;
        event.target.selectionEnd = caretPosition + 1;
        updateBlocks();
      });
    }
  };
  
  // 修改后的 runPromptTool 函数
  const runPromptTool = async (toolCall) => {
    // console.log('接收到的指令: ', JSON.stringify(toolCall));
    let toolMessagesList = [];
  
    // 验证 toolCall 是否为对象并包含必要的字段
    if (typeof toolCall !== 'object' || !toolCall.name) {
      toolMessagesList.push({
        role: 'user',
        content: '指令格式不正确。请确保包含 "name" 字段。',
      });
      return toolMessagesList;
    }
  
    const { name, id, content } = toolCall;
    let funContent = '';
  
    try {
      // 根据指令名称执行相应的操作
      switch (name) {
        case 'write':
          {
            if (!content) throw new Error('缺少 "content" 参数');
            editorContent.value += '\n' + content; // 将新内容追加到编辑器内容
            updateBlocks(); // 更新块
            funContent = `[编辑器响应]指令执行成功，已写入内容。`;
          }
          break;
  
        case 'edit':
          {
            if (!id || !content) throw new Error('缺少 "id" 或 "content" 参数');
            const block = blocks.value.find((b) => b.id === id);
            if (block) {
              block.content = content;
              editorContent.value = blocks.value.map(block => block.content).join('\n'); // 同步编辑器内容
              funContent = `[编辑器响应]指令执行成功，编辑块 ${id} 成功。`;
            } else {
              funContent = `[编辑器响应]指令执行成功，找不到 ID 为 ${id} 的块。`;
            }
          }
          break;
  
        case 'insert':
          {
            if (!id || !content) throw new Error('缺少 "id" 或 "content" 参数');
            const index = blocks.value.findIndex(block => block.id === id);
            if (index !== -1) {
              const newBlock = {
                id: generateRandomId(),
                type: 'text',
                content: content
              };
              blocks.value.splice(index + 1, 0, newBlock); // 在指定块之后插入新块
              editorContent.value = blocks.value.map(block => block.content).join('\n'); // 同步编辑器内容
              funContent = `[编辑器响应]指令执行成功，在块 ${id} 之后插入了新内容。`;
            } else {
              funContent = `[编辑器响应]指令执行成功，找不到 ID 为 ${id} 的块。`;
            }
          }
          break;
  
        case 'delete':
          {
            if (!id) throw new Error('缺少 "id" 参数');
            const index = blocks.value.findIndex(block => block.id === id);
            if (index !== -1) {
              blocks.value.splice(index, 1); // 删除指定块
              editorContent.value = blocks.value.map(block => block.content).join('\n'); // 同步编辑器内容
              funContent = `[编辑器响应]指令执行成功，删除了 ID 为 ${id} 的块。`;
            } else {
              funContent = `[编辑器响应]指令执行成功，找不到 ID 为 ${id} 的块。`;
            }
          }
          break;
  
        case 'clear':
          {
            blocks.value = [{
              id: generateRandomId(),
              type: 'text',
              content: ''
            }];
            editorContent.value = ''; // 清空编辑器内容
            funContent = '[编辑器响应]指令执行成功，编辑器已清空。';
          }
          break;
  
        default:
          throw new Error(`未识别的指令名称: ${name}`);
      }
  
    } catch (error) {
      console.log('解析错误: ' + error.message);
      funContent = `解析错误: ${error.message}`;
    }
  
    // 构建消息对象，仅包含 role 和 content
    toolMessagesList.push({
      role: 'user',
      content: funContent,
    });
  
    addVersion();
    return toolMessagesList;
  };
  
  // 复制和下载功能
  const copyContent = () => {
    navigator.clipboard.writeText(editorContent.value)
      .then(() => alert('内容已复制'))
      .catch(() => alert('复制失败'));
  };
  
  const downloadContent = () => {
    const blob = new Blob([editorContent.value], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `content-${Date.now()}.txt`;
    a.click();
    URL.revokeObjectURL(url);
  };
  
  // 添加版本记录
  const addVersion = () => {
    const newVersion = {
      blocks: JSON.parse(JSON.stringify(blocks.value)),
      timestamp: new Date().toLocaleString()
    };
    versionHistory.value.unshift(newVersion);
  };
  
  // 恢复版本
  const restoreVersion = (index) => {
    const version = versionHistory.value[index];
    blocks.value = JSON.parse(JSON.stringify(version.blocks));
    editorContent.value = blocks.value.map(block => block.content).join('\n');
  };
  
  // 导出版本历史
  const exportVersionHistory = () => {
    const blob = new Blob([JSON.stringify(versionHistory.value)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `version-history-${Date.now()}.json`;
    a.click();
    URL.revokeObjectURL(url);
  };
  
  // 导入版本历史
  const importVersionHistory = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const importedData = JSON.parse(e.target.result);
          versionHistory.value = importedData;
        } catch (err) {
          alert('导入文件格式错误');
        }
      };
      reader.readAsText(file);
    }
  };
  
  // 更新提示词
  const getAppData = () => {
    return `
  ##介绍：  

  你是一个专门解决写作的人工智能助手，你能够在对话中领会用户的需求然后通过指令操作用户的编辑器。
  指令必须是json格式，您可以根据编辑器的情况和用户表达的需求来输出以下JSON指令：
  
  ##工具：

  1. 在编辑器中开始书写，如果编辑器已经存在内容，则会在现有编辑器内容的最后方续写：
  {"name":"write","content": "填写要书写的内容"}
  
  2. 编辑指定块的内容：
  {"name":"edit","id": "要编辑的块的id","content":"编辑后的新内容"}
  
  3. 在指定段落之后插入新段落：
  {"name":"insert","id": "目标段落的id","content":"要插入的内容"}
  
  4. 删除指定的段落内容
  {"name":"delete","id": "要删除的段落id"}
  
  5. 清空编辑器：
  {"name":"clear"}
  
  6. 回复用户，不会对编辑器产生任何影响：
  {"name":"reply","content":"回复内容"}
  
  ##注意：

  务必注意，所有的输出必须是普通的可以被js直接解析的json格式，非json格式会导致错误，不要在输出内容中出现单斜线"/"

  ##方法：
  1.在开始内容较少的情况下，如果用户对内容不满，你应该考虑直接清空编辑器，然后按照用户的需求重新写。
  2.更新内容的时候，一定要注意不要让编辑器出现重复冗余或者被丢弃的内容，要灵活使用delete工具，删除不再需要的内容，保证这是一篇用户能够直接使用的文章。
  3.当需要进行长文写作的时候，可以先写出大纲，然后逐个章节插入。
  4.在对话中，要善于启发用户，激发用户的灵感，或给出有用的建议。

  ##编辑器信息：
  以下是编辑器当前的内容结构：
  ` + JSON.stringify(blocks.value);
  };
  
  onMounted(() => {
    nextTick(() => {
      updateBlocks();
    });
  });
  </script>
  
  <style scoped>
  /* 添加你需要的样式 */
  </style>
  