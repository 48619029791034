<template>
  <div class="flex-grow flex flex-col overflow-hidden">
    <!-- 聊天内容区域 -->
    <div ref="chatContainer" class="flex-grow overflow-y-auto mt-2 px-4 hide-scrollbar max-w-4xl w-full mx-auto">
      <!-- 消息列表 -->
      <div v-for="(message, index) in messages" :key="index">

        <!-- 用户消息 -->
        <div v-if="message.role === 'user'" class="flex justify-end mb-4">
          <div class="bg-gray-200 rounded-lg py-2 px-4 shadow break-all">
            {{ message.content }}
          </div>
        </div>

        <!-- AI助手消息 -->
        <div v-else-if="message.role === 'assistant'" class="flex justify-start mb-4">
          <div class="bg-white text-gray-800 rounded-lg py-2 px-4 shadow break-words">
            <div class="markdown-content" v-html="markdown(message.content)"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 输入区域 -->
    <div class="bg-white w-full p-4 pt-1">
      <div class="bg-gray-200 flex items-end rounded-[23px] p-[5px] shadow-md max-w-4xl mx-auto">
        <textarea v-model="newMessage" rows="1" :placeholder="thinking ? 'AI正在思考...' : '向AI发送消息'" @input="autoResize"
          @keydown.enter.prevent="sendMessage" ref="messageInput"
          class="ml-2 flex-1 p-2 border-none resize-none focus:outline-none overflow-auto min-h-[36px] max-h-[160px] bg-gray-200"></textarea>
        <div @click="sendMessage" :class="[
          'text-2xl mr-1 ml-3 cursor-pointer rounded-full min-h-[38px] min-w-[38px] flex items-center justify-center',
          thinking ? 'bg-gray-400' : (newMessage === '' ? 'bg-gray-400' : 'bg-black')
        ]">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" fill="none" viewBox="0 0 32 32">
            <path fill="white" fill-rule="evenodd"
              d="M15.192 8.906a1.143 1.143 0 0 1 1.616 0l5.143 5.143a1.143 1.143 0 0 1-1.616 1.616l-3.192-3.192v9.813a1.143 1.143 0 0 1-2.286 0v-9.813l-3.192 3.192a1.143 1.143 0 1 1-1.616-1.616z" />
          </svg>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import { ref, reactive, onMounted, nextTick, defineProps, defineExpose } from 'vue';
import { post, extractJSONFromAIOutput } from '../../../../utils';
import { marked } from 'marked';

// 定义 props 和 emits
const props = defineProps({
  model: {
    type: String,
    required: true,
  },
  appDataSync: {
    type: Boolean,
    required: true,
  },
  getAppData: {
    type: Function,
    required: true,
  },
  runPromptTool: {
    type: Function,
    required: true,
  },
});

// const emit = defineEmits(['run-prompt-tool']);

// 定义响应式变量
const newMessage = ref('');

const messages = reactive([
  {
    role: 'user',
    content: ''
  }
]);
const thinking = ref(false);
const chatContainer = ref(null);
const messageInput = ref(null);

// 初始化消息
onMounted(() => {
  scrollToBottom();
});


// 发送消息
const sendMessage = async () => {
  if (newMessage.value.trim() === '') return;

  // 将用户消息添加到消息列表
  messages.push({ role: 'user', content: newMessage.value });
  thinking.value = true;
  newMessage.value = ''; // 清空输入框
  autoResize();
  await ai();
};


const setFirstMessage = (newContent) => {
  messages[0].content = newContent
};


const ai = async () => {
  // eslint-disable-next-line no-constant-condition
  while (true) {

    // 发起请求前，设置思考中
    thinking.value = true;
    scrollToBottom();

    // 同步编辑器的内容
    if (props.appDataSync) {
      const appData = props.getAppData();
      // console.log(appData)
      setFirstMessage(appData)
    }

    // 发起请求
    const aiResponse = await post('/api/ai/chat/openai/o1', {
      model: props.model,
      messages: messages,
    });

    // 处理 AI 响应
    let extractJSONAiResponse = extractJSONFromAIOutput(aiResponse.choices[0].message.content);
    let jsonAiResponse = JSON.parse(extractJSONAiResponse);
    if (jsonAiResponse.name === 'reply') {
      // 回复用户后，思考结束
      messages.push({ role: 'assistant', content: jsonAiResponse.content });
      thinking.value = false;
      scrollToBottom();
      break; // 退出循环
    } else {
      // 处理非回复情况
      messages.push({ role: 'assistant', content: JSON.stringify(jsonAiResponse) });
      const promptToolMessages = await props.runPromptTool(jsonAiResponse);
      console.log('运行结果', promptToolMessages);
      messages.push(...promptToolMessages);
      scrollToBottom();
      // 继续循环，触发新一轮请求
    }

  }
};


// 添加工具消息
// const addPromptToolMessages = (promptToolMessages) => {
//   messages.push(...promptToolMessages);
//   ai();//得到父组件的工具执行结果后，继续运行ai
//   scrollToBottom();
// };

// 暴露方法给父组件
defineExpose({
  setFirstMessage,
  // addPromptToolMessages,
  // sendStartMessage,
});


// 自动调整文本框高度
const autoResize = () => {
  nextTick(() => {
    const textarea = messageInput.value;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  });
};

// 自动滚动到最底部
const scrollToBottom = () => {
  nextTick(() => {
    const container = chatContainer.value;
    container.scrollTop = container.scrollHeight;
  });
};

// 转换 Markdown 内容
const markdown = (content) => {
  return marked(content);
};
</script>
