<template>
    <div class="flex-grow flex flex-col overflow-hidden">
      <!-- 聊天内容区域 -->
      <div ref="chatContainer" class="flex-grow overflow-y-auto mt-2 px-4 hide-scrollbar max-w-4xl w-full mx-auto">
        <!-- 消息列表 -->
        <div v-for="(message, index) in messages" :key="index">
          <div v-if="message.role === 'system'" class="bg-gray-100 text-sm p-2 rounded mb-4">
            {{ message.content }}
          </div>
          <!-- 用户消息 -->
          <div v-if="message.role === 'user'" class="flex justify-end mb-4">
            <div class="bg-gray-200 rounded-lg py-2 px-4 shadow break-all">
              {{ message.content }}
            </div>
          </div>
  
          <!-- AI助手消息 -->
          <div v-else-if="message.role === 'assistant'" class="flex justify-start mb-4">
            <div class="bg-white text-gray-800 rounded-lg py-2 px-4 shadow break-words">
              <div v-html="markdown(message.content)"></div>
              <!-- 工具调用信息 -->
              <div v-if="message.tool_calls">
                <div v-for="(toolCall, idx) in message.tool_calls" :key="idx" class="text-sm text-gray-500 ">
                  <strong>{{ toolCall.function.name }}</strong>
                  <span style="white-space: pre-wrap; word-break: break-word;">
                    {{ toolCall.function.arguments }}
                  </span>
                </div>
              </div>
            </div>
          </div>
  
          <div v-else-if="message.role === 'tool'" class="flex justify-start mb-4">
            <div class="bg-gray-100 text-blue-800 rounded-lg py-2 px-4 shadow break-all text-sm">
              <p v-if="!message.expanded">{{ message.content }}</p>
              <p v-else>{{ message.content }}</p>
            </div>
          </div>
  
        </div>
      </div>
  
      <!-- 输入区域 -->
      <div class="bg-white w-full px-4 pt-1">
        <div class="bg-gray-200 flex items-end rounded-[23px] p-[5px] shadow-md max-w-4xl mx-auto">
          <textarea v-model="newMessage" rows="1" :placeholder="thinking ? 'AI正在思考...' : '向AI发送消息'" @input="autoResize"
            @keydown.enter.prevent="sendMessage" ref="messageInput"
            class="ml-2 flex-1 p-2 border-none resize-none focus:outline-none overflow-auto min-h-[36px] max-h-[160px] bg-gray-200"></textarea>
          <div @click="sendMessage" :class="[
            'text-2xl mr-1 ml-3 cursor-pointer rounded-full min-h-[38px] min-w-[38px] flex items-center justify-center',
            thinking ? 'bg-gray-400' : (newMessage === '' ? 'bg-gray-400' : 'bg-black')
          ]">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8" fill="none" viewBox="0 0 32 32">
              <path fill="white" fill-rule="evenodd"
                d="M15.192 8.906a1.143 1.143 0 0 1 1.616 0l5.143 5.143a1.143 1.143 0 0 1-1.616 1.616l-3.192-3.192v9.813a1.143 1.143 0 0 1-2.286 0v-9.813l-3.192 3.192a1.143 1.143 0 1 1-1.616-1.616z" />
            </svg>
          </div>
        </div>
      </div>
      <div class="text-center text-gray-300 p-1 text-sm">gpt</div>
    </div>
  </template>
  
  <script setup>import { ref, reactive, onMounted, nextTick, defineProps, defineExpose } from 'vue';
  import { marked } from 'marked';
  
  // 定义 props
  const props = defineProps({
    model: {
      type: String,
      required: true,
    },
    tools: {
      type: Array,
      required: true,
    },
    appDataSync: {
      type: Boolean,
      required: true,
    },
    getAppData: {
      type: Function,
      required: true,
    },
    runTool: {
      type: Function,
      required: true,
    },
  });
  
  // 定义响应式变量
  const newMessage = ref('');
  const messages = reactive([
    { role: 'system', content: '' }
  ]);
  const thinking = ref(false);
  const chatContainer = ref(null);
  const messageInput = ref(null);
  
  // 初始化消息
  onMounted(() => {
    scrollToBottom();
  });
  
  // 更新系统消息
  const updateSystemMessage = (newContent) => {
    messages[0].content = newContent;
  };
  
  // 发送消息
  const sendMessage = async () => {
  
    if (newMessage.value.trim() === '') return;
    // 在发送消息前检查 appDataSync 并更新系统消息
    if (props.appDataSync) {
      const appData = props.getAppData();
      updateSystemMessage(appData)
    }
  
    messages.push({ role: 'user', content: newMessage.value });
    thinking.value = true;
    newMessage.value = '';
    scrollToBottom();
    autoResize();
    await ai();
  };
  
  // 处理AI响应
  const ai = async () => {
    thinking.value = true;
  
    try {
      const response = await fetch('/api/ai/chat/together', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ messages: messages, model: props.model, tools: props.tools }),
      });
  
      // console.log('body请求数据：', JSON.stringify({ messages: messages, model: props.model, tools: props.tools }))
  
      if (!response.body) {
        throw new Error('ReadableStream 不可用');
      }
  
      messages.push({ role: 'assistant', content: '' });
      scrollToBottom();
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let buffer = '';
      let toolCalls = [];
  
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
  
        const chunk = decoder.decode(value, { stream: true });
        buffer += chunk;
  
        const lines = buffer.split('\n');
        buffer = lines.pop() || '';
  
        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const jsonData = line.slice(6);
            // console.log('jsonData 对象:', jsonData);
            // 增加日志记录每一行的数据
            console.log(`接收到的原始数据: ${jsonData}`);
  
            if (jsonData === '[DONE]') {
              thinking.value = false;
              scrollToBottom();
              return;
            } else {
              try {
                // 增加日志记录尝试解析的数据
                // console.log(`正在解析的 JSON 数据: ${jsonData}`);
  
                const parsed = JSON.parse(jsonData);
  
                // 增加日志，记录解析后的对象
                // console.log('解析成功的 JSON 数据:', parsed);
  
                const delta = parsed.choices[0]?.delta;
  
                // 记录 delta 对象的状态
                // console.log('Delta 对象:', delta);
  
                if (delta?.content) {
                  messages[messages.length - 1].content += delta.content;
                  scrollToBottom();
                }
  
                if (delta?.tool_calls) {
                  for (const toolCallData of delta.tool_calls) {
                    const toolIndex = toolCallData.index;
  
                    let toolCall = toolCalls.find((t) => t.index === toolIndex);
                    if (!toolCall) {
                      toolCall = { index: toolIndex, function: {} };
                      toolCalls.push(toolCall);
                    }
  
                    // 记录 toolCallData 的状态
                    // console.log('工具调用数据:', toolCallData);
  
                    if (toolCallData.id) toolCall.id = toolCallData.id;
                    if (toolCallData.type) toolCall.type = toolCallData.type;
  
                    if (toolCallData.function?.name) toolCall.function.name = toolCallData.function.name;
                    if (toolCallData.function?.arguments) {
                      toolCall.function.arguments =
                        (toolCall.function.arguments || '') + toolCallData.function.arguments;
                    }
                  }
                }
  
                const finishReason = parsed.choices[0]?.finish_reason;
  
                // 增加日志记录 finishReason 的状态
                // console.log('完成原因:', finishReason);
  
                if (finishReason === 'tool_calls') {
                  messages[messages.length - 1].tool_calls = toolCalls;
  
                  console.log('工具调用:', toolCalls);
  
                  const toolResults = await props.runTool(toolCalls);
                  addToolMessages(toolResults);
                  thinking.value = false;
                  scrollToBottom();
                }
              } catch (error) {
                // 增加日志记录详细的错误信息和原始 JSON 数据
                // console.error(`解析数据错误: ${error.message}`, error);
                // console.error(`解析失败的原始 JSON 数据: ${jsonData}`);
              }
            }
          }
        }
  
      }
    } catch (error) {
      console.error('发送消息时出错:', error);
      thinking.value = false;
    }
  };
  
  // 添加工具消息
  const addToolMessages = (toolMessages) => {
    messages.push(...toolMessages);
    ai();
    scrollToBottom();
  };
  
  // 自动调整文本框高度
  const autoResize = () => {
    nextTick(() => {
      const textarea = messageInput.value;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    });
  };
  
  // 自动滚动到最底部
  const scrollToBottom = () => {
    nextTick(() => {
      const container = chatContainer.value;
      container.scrollTop = container.scrollHeight;
    });
  };
  
  // 转换 Markdown 内容
  const markdown = (content) => {
    return marked(content);
  };
  
  // 发送开始消息
  const sendStartMessage = async (message) => {
    messages.push({ role: 'user', content: message });
    thinking.value = true;
    await ai();
  };
  
  // 暴露方法给父组件
  defineExpose({
    sendStartMessage,
    updateSystemMessage,
    addToolMessages,
  });
  </script>
  